import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { intersectionWith } from 'lodash'
import { useMemo } from 'react'
import { isNotNullOrUndefined } from 'shared/guards'
import { useAccountSelectorTableStore } from '../../features/AccountSelector'
import {
  ICustomAccountGroup,
  useRdot360_getCustomAccountGroupsQuery
} from '../dynamics'
import { useRdot360Context } from './rdot360Context'
import { useRdot360AccountContext } from './useRdot360AccountContext'

export const useRdot360CagsContext = (skipApiCall?: boolean) => {
  const { selectedHouseholdId } = useRdot360Context()
  const skip = !selectedHouseholdId || skipApiCall
  const {
    data: cagsData,
    isFetching,
    isError,
    error
  } = useRdot360_getCustomAccountGroupsQuery(
    skip ? skipToken : selectedHouseholdId
  )
  const cags = skip || isError ? undefined : cagsData
  const { accountLookupByAccountIdOrKey, accounts } = useRdot360AccountContext()

  const { filteredAccounts } = useAccountSelectorTableStore()

  const filteredAccountNumbers = useMemo(
    () => filteredAccounts?.map((x) => x?.accountId),
    [filteredAccounts]
  )

  const accountsInCags = useMemo(
    () =>
      cags
        ?.flatMap((x) => x.rcm_cag_rcm_financialaccount)
        ?.map((x) => accountLookupByAccountIdOrKey[x?.rcm_cdmaccountid || ''])
        ?.map((x) => x?.id)
        ?.filter(isNotNullOrUndefined),
    [accountLookupByAccountIdOrKey, cags]
  )

  const ungroupedAccounts = useMemo(
    () =>
      isFetching
        ? []
        : accounts?.filter((x) => x?.id && !accountsInCags?.includes(x?.id)),
    [accounts, accountsInCags, isFetching]
  )

  const ungroupedCag = useMemo(
    () =>
      ({
        rcm_name: 'Ungrouped Accounts',
        rcm_cag_rcm_financialaccount: ungroupedAccounts?.map((x) => ({
          rcm_accountnumber: x?.id,
          rcm_cdmaccountid: x?.accountId
        })),
        rcm_customaccountgroupid: 'Ungrouped Accounts'
      } as ICustomAccountGroup),
    [ungroupedAccounts]
  )

  const cagsWithUngrouped = useMemo(
    () => [...(cags || []), ungroupedCag],
    [cags, ungroupedCag]
  )

  const filteredCagsWithUngrouped = useMemo(
    () =>
      cagsWithUngrouped
        .map((cag) => ({
          ...cag,
          rcm_cag_rcm_financialaccount: intersectionWith(
            cag.rcm_cag_rcm_financialaccount,
            filteredAccountNumbers || [],
            (a, b) => a?.rcm_cdmaccountid === b
          )
        }))
        ?.filter((x) => x?.rcm_cag_rcm_financialaccount),
    [cagsWithUngrouped, filteredAccountNumbers]
  )

  return {
    cags,
    isFetching,
    error,
    cagsWithUngrouped,
    filteredCagsWithUngrouped,
    filteredAccountNumbers
  }
}
